/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
  * This injects Tailwind's component classes and any component classes
  * registered by plugins.
  */
@tailwind components;

/**
  * This injects Tailwind's utility classes and any utility classes registered
  * by plugins.
  */
@tailwind utilities;


@layer utilities {
  .base {
    @apply w-full rounded-lg py-2 px-4 font-semibold text-black shadow-md shadow-sky-400/50 drop-shadow-lg;
    background: linear-gradient(145deg, #9e9e9e, #c5c5c5); /* Gradient for metal effect */
    border: 2px solid #8a8a8a; /* Border for a more defined look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), inset 0 0 10px rgba(255, 255, 255, 0.2); /* Subtle inner shadow for metallic shine */
    transition: all 0.3s ease-in-out; /* Smooth transition */
  }
  
  .base:hover {
    background: linear-gradient(145deg, #c5c5c5, #9e9e9e); /* Reverse gradient on hover for depth */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), inset 0 0 15px rgba(255, 255, 255, 0.3); /* Stronger hover shadows */
    transform: translateY(-2px); /* Button lift effect on hover */
  }
  
  .base:focus {
    outline: none; /* Remove default outline */
    ring: 2px solid #c5c5c5; /* Focus ring with light metallic color */
    ring-opacity: 0.75;
  }
  
  .base:active {
    background: linear-gradient(145deg, #9e9e9e, #c5c5c5); /* Keep metal color on active press */
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.4); /* Inner pressed effect */
    transform: translateY(1px); /* Button press effect */
  }
  
  .transition-piece {
    transition: transform 300ms, opacity 600ms;
    @apply ease-in;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400&display=swap');

/**
  * Use this directive to control where Tailwind injects the hover, focus,
  * responsive, dark mode, and other variants of each class.
  *
  * If omitted, Tailwind will append these classes to the very end of
  * your stylesheet by default.
  */
/* @tailwind variants; */

html {
  min-height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
  background-color: #4b443c;
  background-image: url('./images/age.png');
  background-size: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glassmorphism{
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(30px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  }